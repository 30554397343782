export default {
  en: {
    row1: [
      {
        alt: "Sia",
        title: "Sia ",
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd275f2659d4bc2b00cb46.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd277a656b227e0d0ad3d7.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd27692659d4bc2b00cb48.jpeg 800w, https://images.projectcarmen.com/resource/ads/64dd275f2659d4bc2b00cb46.jpeg 1000w`
      },
      {
        alt: "Ariana Grande",
        title: "Ariana Grande Positions Album Cover",
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd27939af26df91a0e02e7.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd27fc656b227e0d0ad3db.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd27ec2659d4bc2b00cb4a.jpeg 800w, https://images.projectcarmen.com/resource/ads/64dd27d5656b227e0d0ad3d9.jpeg 1080w, https://images.projectcarmen.com/resource/ads/64dd27939af26df91a0e02e7.jpeg 1400w`
      },
      {
        alt: "Girl in black dress",
        title: 'Girl in black dress',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd28872659d4bc2b00cb4c.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Benny Blanco",
        title: 'Benny Blanco & Marshmello and Vance_Joy - You',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd283e9af26df91a0e02eb.png",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Sam fisher and Demy Lovato",
        title: 'Sam fisher and Demy Lovato',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd292b9af26df91a0e02ef.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Harry Styles",
        title: 'Harry Styles',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2ce22659d4bc2b00cb60.jpeg",
        sizes: `https://images.projectcarmen.com/resource/ads/64dd28d9656b227e0d0ad3dd.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2ce22659d4bc2b00cb60.jpeg 800w`,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Homegrown",
        title: 'Homegrown',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd28e52659d4bc2b00cb4e.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Bruno Mars & Cardy B",
        title: 'Bruno Mars & Cardy B - Thats what I like',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd284f9af26df91a0e02ed.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Bad Bunny",
        title: 'Bad Bunny',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd280c9af26df91a0e02e9.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      }
    ],
    row2: [
      {
        alt: "Kito",
        title: 'Kito',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a6c9af26df91a0e02f3.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Beyonce",
        title: 'Beyonce',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2985656b227e0d0ad3df.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Chloe x Halle",
        title: 'Chloe x Halle - Do it',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd29ac2659d4bc2b00cb52.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Bad Bunny",
        title: 'Bad Bunny',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd280c9af26df91a0e02e9.jpeg",
        sizes: null,
        srcSet: null,
      },
      {
        alt: "Eminem",
        title: 'The Eminem Show',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd29ec9af26df91a0e02f1.jpeg",
        sizes: null,
        srcSet: null,
      },
      {
        alt: "Lizzo",
        title: 'Lizzo Coconut',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a812659d4bc2b00cb54.png",
        sizes: null,
        srcSet: null,
      },
      {
        alt: "NF",
        title: 'NF Perception',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a8b9af26df91a0e02f5.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Lil Durk",
        title: 'Where They Go Song by Lil Durk',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a77656b227e0d0ad3e1.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Red Hot Chilli Peppers",
        title: 'Red Hot Chilli Peppers - Californication',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a95656b227e0d0ad3e3.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Rap Artists",
        title: 'Rap Artist',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a9f2659d4bc2b00cb56.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
    ],
    row3: [
      {
        alt: "Janelle Monáe",
        title: 'Dirty Computer Studio album by Janelle Monáe',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c46656b227e0d0ad3eb.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Weeknd",
        title: 'Starboy by Weeknd',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2bd4656b227e0d0ad3e5.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd2bf6656b227e0d0ad3e7.jpeg 500w,  https://images.projectcarmen.com/resource/ads/64dd2bec9af26df91a0e02f7.jpeg 800w,  https://images.projectcarmen.com/resource/ads/64dd2be22659d4bc2b00cb58.jpeg 1080w,  https://images.projectcarmen.com/resource/ads/64dd2bd4656b227e0d0ad3e5.jpeg 1500w`
      },
      {
        alt: "24kGoldn",
        title: '24kGoldn',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c65656b227e0d0ad3ed.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Doja Cat",
        title: 'Hot pink by Doja Cat',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c502659d4bc2b00cb5e.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Luke Bryan",
        title: 'Luke Bryan Born Here Live Here Die Here album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c389af26df91a0e02fb.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Post Malone",
        title: 'Post Malone Beerbongs &amp; Bentleys album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c109af26df91a0e02f9.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Marshmello",
        title: 'Marshmello and Bastille Happier',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c21656b227e0d0ad3e9.png",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Maroon 5",
        title: 'Maroon 5 Overexposed album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c2c2659d4bc2b00cb5c.png",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Sofia Valdes",
        title: 'Ventura Sofia Valdes album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c052659d4bc2b00cb5a.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Beyonce",
        title: 'Beyonce bDay album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c5b9af26df91a0e02fd.png",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
    ]
  },
  es: {
    row1: [
      {
        alt: "Sia",
        title: "Sia ",
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd275f2659d4bc2b00cb46.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd277a656b227e0d0ad3d7.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd27692659d4bc2b00cb48.jpeg 800w, https://images.projectcarmen.com/resource/ads/64dd275f2659d4bc2b00cb46.jpeg 1000w`
      },
      {
        alt: "Ariana Grande",
        title: "Ariana Grande Positions Album Cover",
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd27939af26df91a0e02e7.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd27fc656b227e0d0ad3db.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd27ec2659d4bc2b00cb4a.jpeg 800w, https://images.projectcarmen.com/resource/ads/64dd27d5656b227e0d0ad3d9.jpeg 1080w, https://images.projectcarmen.com/resource/ads/64dd27939af26df91a0e02e7.jpeg 1400w`
      },
      {
        alt: "Bebe Rexa",
        title: 'Bebe Rexa Album Cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2d139af26df91a0e02ff.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd2d53656b227e0d0ad3ef.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2d139af26df91a0e02ff.jpeg 800w`
      },
      {
        alt: "Dana Paola",
        title: 'Dana Paola album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2db79af26df91a0e0321.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Karol G",
        title: 'Karol G Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2e502659d4bc2b00cb66.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd2e719af26df91a0e0325.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2e502659d4bc2b00cb66.jpeg 600w`
      },
      {
        alt: "Humbe",
        title: 'Humbe album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2e0b2659d4bc2b00cb64.jpeg",
        sizes: `https://images.projectcarmen.com/resource/ads/64dd2e169af26df91a0e0323.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2e0b2659d4bc2b00cb64.jpeg 640w`,
        srcSet: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
      },
      {
        alt: "Harry Styles",
        title: 'Harry Styles Fine Line album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2ce22659d4bc2b00cb60.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd28d9656b227e0d0ad3dd.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2ce22659d4bc2b00cb60.jpeg 600w`,
        className: "last-image"
      },
      {
        alt: "Christian Nodal",
        title: 'Christian Nodal album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2d86656b227e0d0ad3f1.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd2d912659d4bc2b00cb62.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2d86656b227e0d0ad3f1.jpeg 800w`,
        className: "last-image"
      },
      {
        alt: "J Balvin",
        title: 'J Balvin album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2dc5656b227e0d0ad3f3.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Bad Bunny",
        title: 'Bad Bunny',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd280c9af26df91a0e02e9.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      }
    ],
    row2: [
      {
        alt: "J Balvin",
        title: 'J Balvin album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2dc5656b227e0d0ad3f3.jpeg",
        sizes: null,
        srcSet: null,
      },
      {
        alt: "Maroon 5",
        title: 'Maroon 5 Overexposed album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c2c2659d4bc2b00cb5c.png",
        sizes: null,
        srcSet: null,
      },
      {
        alt: "Camilo Mis Manos",
        title: 'Camilo Mis Manos Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2f40656b227e0d0ad3f7.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Bad Bunny",
        title: 'Bad Bunny',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd280c9af26df91a0e02e9.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Selena Gomez",
        title: 'Selena Gomez Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd30382659d4bc2b00cb6c.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd30419af26df91a0e032d.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd30382659d4bc2b00cb6c.jpeg 800w`
      },
      {
        alt: "Piso",
        title: 'Piso Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd30192659d4bc2b00cb6a.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd302e656b227e0d0ad3fb.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd30249af26df91a0e032b.jpeg 800w, https://images.projectcarmen.com/resource/ads/64dd30192659d4bc2b00cb6a.jpeg 840w`
      },
      {
        alt: "Blackpink",
        title: 'Blackpink album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2f229af26df91a0e0327.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Justin Bieber",
        title: 'Justin Bieber album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2f829af26df91a0e0329.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd2f9c656b227e0d0ad3f9.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2f829af26df91a0e0329.jpeg 800w`,
        className: "last-image"
      },
      {
        alt: "Bad Bunny",
        title: 'Bad Bunny album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2edb656b227e0d0ad3f5.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd2ee62659d4bc2b00cb68.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2edb656b227e0d0ad3f5.jpeg 800w`,
        className: "last-image"
      },
      {
        alt: "Rap Artists",
        title: 'Rap Artist',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a9f2659d4bc2b00cb56.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      }
    ],
    row3: [
      {
        alt: "Pandora",
        title: 'Pandora Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd328d9af26df91a0e0342.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Alejandro Fernandez",
        title: 'Alejandro Fernandez album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd31422659d4bc2b00cb6e.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd314b9af26df91a0e032f.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd31422659d4bc2b00cb6e.jpeg 800w`
      },
      {
        alt: "Reik x J Balvin x Lalo Ebrat",
        title: 'Reik x J Balvin x Lalo Ebrat album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd32029af26df91a0e0335.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd321e9af26df91a0e0337.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd32029af26df91a0e0335.jpeg 800w`
      },
      {
        alt: "Taylor Swift",
        title: 'Taylor Swift album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd32a62659d4bc2b00cb82.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd32b1656b227e0d0ad405.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd32a62659d4bc2b00cb82.jpeg 800w`
      },
      {
        alt: "Billie Eilish",
        title: 'Billie Eilish Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd316b9af26df91a0e0331.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "BTS",
        title: 'BTS album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd3157656b227e0d0ad3fd.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd31622659d4bc2b00cb70.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd3157656b227e0d0ad3fd.jpeg 800w`
      },
      {
        alt: "Natanel Cano",
        title: 'Natanel Cano Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd327b2659d4bc2b00cb7d.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Maluma",
        title: 'Maluma album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd323e2659d4bc2b00cb79.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd324a2659d4bc2b00cb7b.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd323e2659d4bc2b00cb79.jpeg  625w`,
        className: "last-image"
      },
      {
        alt: "Caloncho",
        title: 'Caloncho Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd31c59af26df91a0e0333.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Calibre",
        title: 'Calibre album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd3194656b227e0d0ad3ff.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd31b82659d4bc2b00cb72.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd3194656b227e0d0ad3ff.jpeg  750w`,
        className: "last-image"
      }
    ]
  },
  id: {
    row1: [
      {
        alt: "Sia",
        title: "Sia ",
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd275f2659d4bc2b00cb46.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd277a656b227e0d0ad3d7.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd27692659d4bc2b00cb48.jpeg 800w, https://images.projectcarmen.com/resource/ads/64dd275f2659d4bc2b00cb46.jpeg 1000w`
      },
      {
        alt: "Amber Mak Pyar",
        title: "Happy Asmara album cover",
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd332b9af26df91a0e0350.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd33349af26df91a0e0352.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd332b9af26df91a0e0350.jpeg 640w`
      },
      {
        alt: "Indonesia Music",
        title: 'Indonesia Music Album cover',
        mainImage: " https://images.projectcarmen.com/resource/ads/64ddc2319af26df91a0e043a.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Charlie Puth",
        title: 'Charlie Puth Lightswitch Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dda5ae656b227e0d0ad510.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Dewa",
        title: 'Dewa Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd337d9af26df91a0e0357.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "BimBang",
        title: "BimBang album cover",
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd3367656b227e0d0ad43c.jpeg",
        sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd33712659d4bc2b00cba6.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd3367656b227e0d0ad43c.jpeg 640w`
      },
      {
        alt: "Taylor Swift",
        title: 'Taylor Swift album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd32a62659d4bc2b00cb82.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd32b1656b227e0d0ad405.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd32a62659d4bc2b00cb82.jpeg 800w`,
        className: "last-image"
      },
      {
        alt: "Febi Putri",
        title: 'Febi Putri Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd33b1656b227e0d0ad43e.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "J Balvin",
        title: 'J Balvin album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2dc5656b227e0d0ad3f3.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Bad Bunny",
        title: 'Bad Bunny',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd280c9af26df91a0e02e9.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
    ],
    row2: [
      {
        alt: "J Balvin",
        title: 'J Balvin album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2dc5656b227e0d0ad3f3.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Maroon 5",
        title: 'Maroon 5 Overexposed album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2c2c2659d4bc2b00cb5c.png",
        sizes: null,
        srcSet: null
      },
      {
        alt: "The Wtitleers",
        title: 'The Wtitleers - love you album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc4e19af26df91a0e0440.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc4ee656b227e0d0ad53d.jpeg 500w, https://images.projectcarmen.com/resource/ads/64ddc4e19af26df91a0e0440.jpeg 640w`,
      },
      {
        alt: "Indonesia Music",
        title: 'Indonesia Music album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64df7818656b227e0d0add13.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Indonesia Music",
        title: 'Indonesia Music album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc4662659d4bc2b00cd6e.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Indonesia Music",
        title: 'Indonesia Music album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc44a9af26df91a0e043e.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc457656b227e0d0ad53b.jpeg 500w, https://images.projectcarmen.com/resource/ads/64ddc44a9af26df91a0e043e.jpeg 800w`,
      },
      {
        alt: "Ed Sheeran",
        title: 'Ed Sheeran album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc40b656b227e0d0ad539.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc41a2659d4bc2b00cd6c.jpeg 500w, https://images.projectcarmen.com/resource/ads/64ddc40b656b227e0d0ad539.jpeg 800w`,
        className: "last-image"
      },
      {
        alt: "Indonesia Music",
        title: 'Butterfly album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc3c52659d4bc2b00cd6a.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc3d19af26df91a0e043c.jpeg 500w, https://images.projectcarmen.com/resource/ads/64ddc3c52659d4bc2b00cd6a.jpeg 640w`,
        className: "last-image"
      },
      {
        alt: "Bad Bunny",
        title: 'Bad Bunny album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2edb656b227e0d0ad3f5.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd2ee62659d4bc2b00cb68.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd2edb656b227e0d0ad3f5.jpeg 800w`,
        className: "last-image"
      },
      {
        alt: "Rap Artists",
        title: 'Rap Artist',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd2a9f2659d4bc2b00cb56.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
    ],
    row3: [
      {
        alt: "Pandora",
        title: 'Pandora Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd328d9af26df91a0e0342.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Nuka Mahalini ",
        title: 'Nuka Mahalini album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc6609af26df91a0e0444.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc6542659d4bc2b00cd74.jpeg 500w, https://images.projectcarmen.com/resource/ads/64ddc6609af26df91a0e0444.jpeg 640w`
      },
      {
        alt: "Indonesia Music",
        title: 'Indonesia Music Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc5b4656b227e0d0ad53f.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Rafi Sudrman",
        title: 'Rafi Sudrman Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc6c12659d4bc2b00cd76.jpeg",
        sizes: null,
        srcSet: null
      },
      {
        alt: "Indonesia Music ",
        title: 'Indonesia Music album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc6229af26df91a0e0442.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc62d656b227e0d0ad543.jpeg 500w, https://images.projectcarmen.com/resource/ads/64ddc6229af26df91a0e0442.jpeg 720w`
      },
      {
        alt: "Indonesia Music ",
        title: 'Indonesia Music album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc5ea656b227e0d0ad541.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc5f72659d4bc2b00cd72.jpeg 500w, https://images.projectcarmen.com/resource/ads/64ddc5ea656b227e0d0ad541.jpeg 640w`
      }, 
      {
        alt: "ColdPlay",
        title: 'ColdPlay Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64ddc5512659d4bc2b00cd70.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Pacar Selingan",
        title: 'Pacar Selingan Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64df7e169af26df91a0e0cc5.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64ddc6b3656b227e0d0ad545.jpeg 500w, https://images.projectcarmen.com/resource/ads/64df7e169af26df91a0e0cc5.jpeg 800w`,
        className: "last-image"
      },
      {
        alt: "Caloncho",
        title: 'Caloncho Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd31c59af26df91a0e0333.jpeg",
        sizes: null,
        srcSet: null,
        className: "last-image"
      },
      {
        alt: "Calibre",
        title: 'Calibre Album cover',
        mainImage: "https://images.projectcarmen.com/resource/ads/64dd3194656b227e0d0ad3ff.jpeg",
        sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
        srcSet: `https://images.projectcarmen.com/resource/ads/64dd31b82659d4bc2b00cb72.jpeg 500w, https://images.projectcarmen.com/resource/ads/64dd3194656b227e0d0ad3ff.jpeg 750w`,
        className: "last-image"
      }
    ]
  }
}
