
import musicAlbums from '@/constants/musicAlbums';
export default {
  props: {
    positiveInteger: {
      type: Number,
      default: 100
    },
    negativeInteger: {
      type: Number,
      default: -100
    }
  },
  data() {
    return {
      albums: {}
    }
  },
  mounted() {
    this.albums = musicAlbums[this.$i18n.locale]
  },
  methods: {
    getStyles(rowNumber) {
      let rotationValue = (rowNumber % 2 === 0) ? this.negativeInteger : this.positiveInteger
      return {
        transform: `translate3d(${rotationValue}px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        willChange: 'transform',
        transformStyle: 'preserve-3d',
      };
    }
  }
}
